import React from 'react';
import { Modal } from 'react-bootstrap';
import { Pergunta, Alert } from './Alert';
import { api } from '../views/Api/api';

class ModalCancelarProposta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            id: 0,
            motivoCancelamento: ''
        }
        this.AbrirModal = (id) => this.setState({ show: true, id });
    }

    CancelarProposta = async () => {
        if (await Pergunta('Deseja realmente cancelar essa proposta?')) {
            api.post('api/Proposta/Cancelar', {
                "propostaId": this.state.id,
                "motivoCancelamento": this.state.motivoCancelamento
            }, res => {
                Alert(res.data);
                this.setState({ show: false });
            }, err => {
                Alert('Erro ao cancelar a proposta', true);
            })
        }
    }


    render() {
        return (
            <Modal show={this.state.show}>
                <form onSubmit={e => { e.preventDefault(); this.CancelarProposta() }}>
                    <Modal.Header>
                        Cancelar Proposta Id: {this.state.id}
                    </Modal.Header>
                    <Modal.Body>
                        <label>Motivo Cancelamento</label>
                        <textarea
                            required
                            className='form-control'
                            maxLength={200}
                            onChange={e => this.setState({ motivoCancelamento: e.target.value })}
                            value={this.state.motivoCancelamento}>
                        </textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='btn btn-info' onClick={() => this.setState({ show: false, id: 0, motivoCancelamento: '' })}>Voltar</button>
                        <button type='submit' className='btn btn-primary'>Enviar</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default ModalCancelarProposta;