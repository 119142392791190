import Swal from "sweetalert2";

export const Alert = (msg, erro = false, info = false) => {
    Swal.fire({
        icon: erro ? "error" : info ? "info" : "success",
        title: "Atenção",
        text: msg,
    });
}

export const Pergunta = (texto) => {
    return new Promise((resolve) => {
        Swal.fire({
            title: texto,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO"
        }).then((result) => {
            resolve(result.isConfirmed);
        });
    });
};