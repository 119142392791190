import axios from "axios"
import { Alert } from '../../components/Alert';
import { api } from './api';

const GetToken = () => { const token = window.localStorage.getItem("access_token"); return token ? atob(token) : ""; }

const GetCaptcha = (res, erro) => {
    api.get(`api/login/captcha`, response => res(response), err => Alert(err.response.data, true));
}

const GetIp = (res, erro) => {
    api.get(`api/login/meuip`, response => res(response), err => Alert(err.response.data, true))
}

const PostValidarLogin = (form, callBack) => {
    api.post(`api/login/validar`, form, response => callBack(response), err => Alert(err.response.data, true))
}

const GetBuscarDadosDoCpf = (cpf, callBack) => {
    api.get(`api/Cpf/BuscarDadosPorCpf?cpf=${cpf}&tipo=6`,
        response => callBack(response),
        err => Alert(err.response.data, err.response.status !== 404, err.response.status === 404))
}

const PostGerarProposta = (form, callBack) => {
    api.post(`api/proposta`, form, response => callBack(response), err => Alert(err.response.data, true))
}

const PutatualizarProposta = (form, callBack) => {
    api.put(`api/proposta`, form, response => callBack(response), err => Alert(err.response.data, true))
}


const GetBuscarPropostaById = (id, callBack) => {
    api.get(`api/Proposta/${id}`, response => callBack(response), err => Alert(err.response.data, true))
}

export { GetCaptcha, GetIp, PostValidarLogin, GetToken, GetBuscarDadosDoCpf, PostGerarProposta, GetBuscarPropostaById, PutatualizarProposta };