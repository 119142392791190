import React from 'react';
import { Modal } from 'react-bootstrap';
import { Pergunta, Alert } from './Alert';
import { api } from '../views/Api/api';

class ModalRecusarAnexo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            id: 0,
            motivo: '',
            data: null,
            propostaId: 0
        }
        this.AbrirModal = (data, propostaId) => this.setState({ show: true, data, propostaId });
    }

    RecusarAnexo = async () => {
        const { data, propostaId } = this.state;
        data.observacao = this.state.motivo;
        if (await Pergunta('Deseja realmente recusar este anexo?')) {
            api.post('api/Proposta/RecusarAnexo', data, res => {
                this.props.BuscarDados(propostaId)
                this.setState({ show: false })
                Alert('Anexo recusado com sucesso!');
            }, err => Alert('Erro ao recusar o anexo!', true));
        }
    }


    render() {
        return (
            <Modal show={this.state.show}>
                <form onSubmit={e => { e.preventDefault(); this.RecusarAnexo() }}>
                    <Modal.Header>
                        Recusar Anexo
                    </Modal.Header>
                    <Modal.Body>
                        <label>Motivo:</label>
                        <textarea
                            required
                            className='form-control'
                            maxLength={200}
                            onChange={e => this.setState({ motivo: e.target.value })}
                            value={this.state.motivo}>
                        </textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='btn btn-info' onClick={() => this.setState({ show: false, data: null, motivo: '' })}>Voltar</button>
                        <button type='submit' className='btn btn-primary'>Enviar</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default ModalRecusarAnexo;