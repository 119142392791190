import { useEffect, useState, useRef } from "react";
import { GetToken, GetBuscarDadosDoCpf, PostGerarProposta, GetBuscarPropostaById, PutatualizarProposta } from "../Api/methodsApi";
import React from 'react';
import Form from './form';
import { Container } from 'react-bootstrap';
import { FaEye, FaPaperclip, FaTrash } from "react-icons/fa6";
import { FaCubes } from "react-icons/fa";
import { FcEditImage, FcImageFile, FcRemoveImage } from "react-icons/fc";
import { validaCPF } from '../../util/validators';
import { Alert, Pergunta } from "../../components/Alert";
import Loader from "../../components/Loader/loader";
import ModalViisualizarImagem from "../../components/ModalVisualizarImagem";
import { api } from "../Api/api";
import { GetParametro } from "../../util/parametro";
import { MdBlock } from "react-icons/md";
import ModalRecusarAnexo from "../../components/ModalRecusarAnexo";

const Proposta = () => {
    const modal = useRef();
    const recusarAnexo = useRef();
    const initstate = {
        cpf: '',
        nome: '',
        estadoCivil: 'Selecione',
        sexo: 'Masculino',
        dataNascimento: '',
        matricula: '',
        identidade: '',
        ufIdentidade: '',
        orgaoEmissor: '',
        dataEmissao: '',
        telefoneCelular: '',
        telefoneFixo: '',
        emailPessoal: '',
        emailCorporativo: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
        nomeMae: ''
    }
    const camposObrigatorios = {
        cpf: 'CPF',
        nome: 'NOME DO CLIENTE',
        estadoCivil: 'ESTADO CIVIL',
        dataNascimento: 'DATA DE NASCIMENTO',
        matricula: 'MATRICULA',
        identidade: 'N° IDENTIDADE',
        telefoneCelular: 'TELEFONE CELULAR',
        nomeMae: 'NOME DA MÃE',
        cep: 'CEP',
        logradouro: 'LOGRADOURO',
        numero: 'NUMERO RESIDENCIAL',
        bairro: 'BAIRRO',
        cidade: 'CIDADE',
        uf: 'UF',
    }
    const camposNaoObrigatorios = { "sexo": '', "ufIdentidade": '', "orgaoEmissor": '', "dataEmissao": '', "telefoneFixo": '', "emailCorporativo": '', "complemento": '', "emailPessoal": '' };
    const [formData, setFormData] = useState(initstate);

    const [docFrente, setDocFrente] = useState(null);
    const [docVerso, setDocVerso] = useState(null);
    const [docSelfie, setDocSelfie] = useState(null);

    const [isEdit, setisEdit] = useState(false);
    const [nomeStatus, setNomeStatus] = useState('');
    const [propostaId, setPropostaId] = useState(0);
    const [readOnly, setReadOnly] = useState(false);
    const [historico, setHistorico] = useState([]);

    const [objAnexoFrente, setObjAnexoFrente] = useState(null);
    const [objAnexoVerso, setObjAnexoVerso] = useState(null);
    const [objAnexoSelfie, setObjAnexoSelfie] = useState(null);

    const BuscarDados = (id) => {
        GetBuscarPropostaById(id, res => {
            const {
                nomeCliente,
                cpf,
                estadoCivil,
                sexo,
                docIdentidade,
                docIdentidadeUf,
                docIdentidadeOrgEmissor,
                docIdentidadeDataEmissao,
                nomeMae,
                nomePai,
                emailPessoal,
                telefonePessoal,
                emailCorporativo,
                telefoneCorporativo,
                logradouro,
                bairro,
                cep,
                cidade,
                uf,
                complemento,
                endNumero,
                dataNascimento,
                matricula,
            } = res.data;

            const data = {
                cpf: cpf,
                nome: nomeCliente,
                estadoCivil: estadoCivil,
                sexo: sexo,
                dataNascimento: getDateFormataraDDMMYYYY(dataNascimento),
                matricula: matricula,
                identidade: docIdentidade,
                ufIdentidade: docIdentidadeUf,
                orgaoEmissor: docIdentidadeOrgEmissor,
                dataEmissao: getDateFormataraDDMMYYYY(docIdentidadeDataEmissao),
                telefoneCelular: telefonePessoal,
                telefoneFixo: telefoneCorporativo,
                emailPessoal,
                emailCorporativo,
                cep: cep,
                logradouro: logradouro,
                numero: endNumero,
                complemento: complemento,
                bairro: bairro,
                cidade: cidade,
                uf: uf,
                nomeMae: nomeMae,
            };
            setFormData(prevFormData => ({ ...prevFormData, ...data }));
            res.data.propostaAnexos.forEach(anexo => {
                if (anexo.tipoDocumentoId === 1) {
                    handdleBuscarAnexo(anexo.id, setDocFrente)
                    setObjAnexoFrente(anexo);
                }
                else if (anexo.tipoDocumentoId === 2) {
                    handdleBuscarAnexo(anexo.id, setDocVerso)
                    setObjAnexoVerso(anexo);
                }
                else if (anexo.tipoDocumentoId === 3) {
                    handdleBuscarAnexo(anexo.id, setDocSelfie)
                    setObjAnexoSelfie(anexo);
                }
            })
            setHistorico(res.data.propostaHistoricos);
            setNomeStatus(res.data.nomeStatus);
            setisEdit(true);
            var readOnly = GetParametro("readOnly");
            setReadOnly(readOnly != null);
        });
    }

    const handdleBuscarAnexo = (anexoId, setAnexo) => {
        api.get(`api/proposta/BuscarAnexo/${anexoId}`, res => {
            setAnexo('data:image/jpeg;base64,' + res.data);
        }, err => {
            Alert('Erro ao buscar o anexo do id: ' + anexoId, true)
        })
    }

    const handdleBuscarAnexoHistorico = (historicoId) => {
        api.get(`api/proposta/BuscarAnexoHistorico/${historicoId}`, res => {
            modal.current.ViisualizarImagem('data:image/jpeg;base64,' + res.data)
        }, err => {
            Alert('Erro ao buscar o anexo do historico id: ' + historicoId, true)
        })
    }

    const handleVerAnexo = (base64) => {
        modal.current.ViisualizarImagem(base64);
    }

    const handleLimparImage = async (setImageState) => {
        if (await Pergunta('Deseja realmente excluir este anexo?')) {
            setImageState(null);
        }
    }

    const handleResucarAnexo = async (anexoObj, anexo) => {
        const data = {
            "id": anexoObj.id,
            "nome": anexoObj.nome,
            "tipoDocumentoId": anexoObj.tipoDocumentoId,
            // "anexo": anexo.replaceAll('data:image/jpeg;base64,', ''),
            "propostaId": propostaId,
            "anexosValidos": []
        };

        if (anexoObj == objAnexoFrente) {
            if (objAnexoVerso) {
                let copy = objAnexoVerso;
                copy.propostaId = propostaId;
                data.anexosValidos.push(copy);
            }

            if (objAnexoSelfie) {
                let copy = objAnexoSelfie;
                copy.propostaId = propostaId;
                data.anexosValidos.push(copy);
            }
        }

        if (anexoObj == objAnexoVerso) {
            if (objAnexoFrente) {
                let copy = objAnexoFrente;
                copy.propostaId = propostaId;
                data.anexosValidos.push(copy);
            }

            if (objAnexoSelfie) {
                let copy = objAnexoSelfie;
                copy.propostaId = propostaId;
                data.anexosValidos.push(copy);
            }
        }

        if (anexoObj == objAnexoSelfie) {
            if (objAnexoFrente) {
                let copy = objAnexoFrente;
                copy.propostaId = propostaId;
                data.anexosValidos.push(copy);
            }

            if (objAnexoVerso) {
                let copy = objAnexoVerso;
                copy.propostaId = propostaId;
                data.anexosValidos.push(copy);
            }
        }

        recusarAnexo.current.AbrirModal(data, propostaId);
    }

    const handleImageUpload = (event, setImageState) => {
        const file = event.target.files[0];
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (file && validTypes.includes(file.type)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageState(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            Alert('Somente é permitido arquivos de imagem. JPG, JPEG ou PNG.', false, true);
        }
    };


    function validarDados(dados) {
        let camposInvalidos = [];
        for (let chave in dados) {
            if (dados.hasOwnProperty(chave) && (dados[chave] === '' || dados[chave] === null) && !camposNaoObrigatorios.hasOwnProperty(chave)) {
                debugger
                camposInvalidos.push(camposObrigatorios[chave]);
            }
        }

        if (camposInvalidos.length > 0) {
            Alert(`Os seguintes campos estão inválidos: ${camposInvalidos.join(', \r\n')}`, false, true);
            return false;
        }

        return true;
    }

    useEffect(() => {
        const token = GetToken();
        if (token == "") window.location.href = '/';

        var id = GetParametro("Id");
        if (id && id !== '') {
            setPropostaId(id);
            BuscarDados(id);
        }
    }, [])

    const handleLimpar = async () => {
        if (await Pergunta("Deseja realmente limpar os campos?")) {
            setFormData(initstate);
            setDocFrente(null);
            setDocVerso(null);
            setDocSelfie(null);
        }
    }

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const MessageResult = async (msg) => {
        Alert(msg);
    }

    const handleSubmit = () => {
        if (!validarDados(formData)) return;

        const data = {
            "nomeCliente": formData.nome,
            "CPF": formData.cpf,
            "estadoCivil": formData.estadoCivil,
            "sexo": formData.sexo ? formData.sexo : "Masculino",
            "docIdentidade": formData.identidade,
            "docIdentidadeUf": formData.uf,
            "docIdentidadeOrgEmissor": formData.orgaoEmissor,
            "docIdentidadeDataEmissao": getDateFormatar_DDMMYYYY_To_YYYYMMDDHHmmSS(formData.dataEmissao),
            "nomeMae": formData.nomeMae,
            "emailPessoal": formData.emailPessoal,
            "telefonePessoal": formData.telefoneCelular,
            "emailCorporativo": formData.emailCorporativo,
            "telefoneCorporativo": formData.telefoneFixo,
            "logradouro": formData.logradouro,
            "bairro": formData.bairro,
            "cep": formData.cep,
            "cidade": formData.cidade,
            "uf": formData.uf,
            "complemento": formData.complemento,
            "endNumero": formData.numero,
            "produtoId": 6,
            "revendedorId": parseInt(atob(window.localStorage.getItem("revendedor_id"))),
            "statusId": 1,
            // "vendedorUsuarioId": 0, // vem pelo UsuarioId
            "dataNascimento": getDateFormatar_DDMMYYYY_To_YYYYMMDDHHmmSS(formData.dataNascimento),
            "matricula": formData.matricula,
            "identificadores": [],
            "tipoPagamentoId": 1,
            "propostaAnexos": []
        }

        if (docFrente) {
            data.propostaAnexos.push({
                "nome": "Documento Frente",
                "tipoDocumentoId": 1,
                "anexo": docFrente.replace('data:image/jpeg;base64,', ''),
                "descricao": "Documento Frente",
            });
        }

        if (docVerso) {
            data.propostaAnexos.push({
                "nome": "Documento Verso",
                "tipoDocumentoId": 2,
                "anexo": docVerso.replace('data:image/jpeg;base64,', ''),
                "descricao": "Documento Verso",
            });
        }

        if (docSelfie) {
            data.propostaAnexos.push({
                "nome": "Documento Selfie",
                "tipoDocumentoId": 3,
                "anexo": docSelfie.replace('data:image/jpeg;base64,', ''),
                "descricao": "Documento Selfie",
            });
        }

        try {
            if (isEdit) {
                data.id = propostaId;
                PutatualizarProposta(data, res => {
                    Alert('Proposta alterada com sucesso!');
                    BuscarDados(propostaId);
                });
            } else {
                PostGerarProposta(data, res => {
                    MessageResult(res.data.message).then(x => {
                        window.location.href = '/propostas';
                    })
                });
            }
        } catch (error) {
        }
    };

    function padLeft(str, length, char = '0') {
        return str.toString().padStart(length, char);
    }

    function getDateFormataraDDMMYYYY(date) {
        if (!date) return null;

        const dataObj = new Date(date);
        const dia = String(dataObj.getDate()).padStart(2, '0');
        const mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
        const ano = dataObj.getFullYear();
        return `${dia}/${mes}/${ano}`;
    }

    function getDateFormataraDDMMYYYYHHmmSS(date) {
        if (!date) return null;

        const dataObj = new Date(date);
        const dia = String(dataObj.getDate()).padStart(2, '0');
        const mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
        const ano = dataObj.getFullYear();
        const hora = dataObj.getHours();
        const min = dataObj.getMinutes();
        const seg = dataObj.getSeconds();

        return `${dia}/${mes}/${ano} ${hora}:${min}:${seg}`;
    }

    function getDateFormatar_DDMMYYYY_To_YYYYMMDDHHmmSS(date) {
        if (!date) return null;

        var split = date.split('/');
        var dia = split[0];
        var mes = split[1];
        var ano = split[2];

        return `${ano}-${mes}-${dia}T00:00:00`;
    }

    const handleValidarCPf = e => {
        let cpfSemMascara = e.target.value.replaceAll('.', '').replaceAll('_', '').replaceAll('-', '');
        if (cpfSemMascara.length === 0) return;
        let cpf = cpfSemMascara.length === 11 ? cpfSemMascara : padLeft(cpfSemMascara, 11);
        let cpfValido = validaCPF(cpf);
        setFormData({ ...formData, cpf: cpf });

        if (cpfValido) {
            GetBuscarDadosDoCpf(cpf.substring(0, 3) + "." + cpf.substring(3, 6) + "." + cpf.substring(6, 9) + "-" + cpf.substring(9, 11), res => {
                if (res.data.length === 0) {
                    Alert('No momento este cliente não está Apto para adquirir este produto!', false, true);
                    return;
                }
                const { bairro
                    , cep
                    , cidade
                    , complemento
                    , dataNascimento
                    , docIdentidade
                    , docIdentidadeOrgEmissor
                    , docIdentidadeUf
                    , email
                    , endNumero
                    , estadoCivil
                    , logradouro
                    , matricula
                    , nomeCliente
                    , nomeMae
                    , sexo
                    , telefone
                    , uf
                } = res.data[0];
                const data = {
                    cpf: e.target.value,
                    nome: nomeCliente,
                    estadoCivil: estadoCivil,
                    sexo: sexo,
                    dataNascimento: getDateFormataraDDMMYYYY(dataNascimento),
                    matricula: matricula,
                    identidade: docIdentidade,
                    ufIdentidade: docIdentidadeUf,
                    orgaoEmissor: docIdentidadeOrgEmissor,
                    telefoneCelular: telefone,
                    emailPessoal: email,
                    cep: cep,
                    logradouro: logradouro,
                    numero: endNumero,
                    complemento: complemento,
                    bairro: bairro,
                    cidade: cidade,
                    uf: uf,
                    nomeMae: nomeMae
                };

                setFormData(prevFormData => ({ ...prevFormData, ...data }));
            })
        } else {
            Alert('Cpf inválido!', false, true);
        }
    }

    const SelecionarArquivo = (fileId) => document.getElementById(fileId).click();

    const handleBuscarbyCep = e => {
        const { value } = e.target;
        if (value.replaceAll('_', '').length > 8) {
            api.get(`api/Cep/${value}`, res => {
                if (res.data.erro)
                    Alert(`CEP: ${value} inválido!`, false, true);

                const {
                    cep, logradouro, complemento, bairro, localidade, uf
                } = res.data;

                const data = {
                    cep, logradouro, complemento, bairro, cidade: localidade, uf
                }

                setFormData(prevFormData => ({ ...prevFormData, ...data }));
            }, err => {
                Alert('Erro ao buscar o CEP', true);
            })
        }

        handleChange(e);
    }

    const handdleRecusarAnexo = () => {

    }

    return (
        <Container className="container-center" style={{ padding: '2.5rem' }}>
            <Loader />
            <ModalViisualizarImagem ref={modal} />
            <ModalRecusarAnexo ref={recusarAnexo} BuscarDados={BuscarDados} />
            <div className="container-proposta">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {!isEdit && <h4><FaCubes /> Cadastro de Propostas</h4>}
                    {isEdit && <h4><FaCubes /> Propostas Id: {propostaId}</h4>}
                    {isEdit && <small>STATUS DA PROPOSTA: <b>{nomeStatus}</b></small>}
                </div>
                <hr />
                <Form
                    formData={formData}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleValidarCPf={handleValidarCPf}
                    handleLimpar={handleLimpar}
                    handleBuscarbyCep={handleBuscarbyCep}
                    readOnly={readOnly}
                    isEdit={isEdit}
                />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <table style={{ width: '80%' }} className="table table-striped">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Tipo de documento</th>
                                <th>Visualizar Anexar/Reenviar</th>
                                {isEdit && <th>Recusar</th>}
                            </tr>
                        </thead>
                        <input type="file" name="docFrente" id="docFrente" onChange={(e) => handleImageUpload(e, setDocFrente)} />
                        <input type="file" name="docVerso" id="docVerso" onChange={(e) => handleImageUpload(e, setDocVerso)} />
                        <input type="file" name="docSelfie" id="docSelfie" onChange={(e) => handleImageUpload(e, setDocSelfie)} />
                        <tbody>
                            <tr>
                                <td>{docFrente ? <FcImageFile size={30} /> : <FcRemoveImage size={30} />} <strong style={{ color: docFrente ? "green" : "red" }}>{docFrente ? "SELECIONADO" : "PENDENTE"}</strong></td>
                                <td>Documento Frente</td>
                                <td style={{ gap: 10, display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                    {docFrente && <button className="btn btn-success" onClick={() => handleVerAnexo(docFrente)}><FaEye size={25} /></button>}
                                    {docFrente && <button className="btn btn-danger" disabled={readOnly} onClick={() => handleLimparImage(setDocFrente)}><FaTrash size={25} /></button>}
                                    {!docFrente && <button disabled={readOnly} onClick={() => SelecionarArquivo('docFrente')} className="btn btn-primary"><FaPaperclip size={25} /></button>}
                                </td>
                                {isEdit && docFrente && objAnexoFrente && <td><button className="btn btn-danger" disabled={readOnly} onClick={() => handleResucarAnexo(objAnexoFrente, docFrente)}><MdBlock size={25} /></button></td>}
                            </tr>
                            <tr>
                                <td>{docVerso ? <FcImageFile size={30} /> : <FcRemoveImage size={30} />} <strong style={{ color: docVerso ? "green" : "red" }}>{docVerso ? "SELECIONADO" : "PENDENTE"}</strong></td>
                                <td>Documento Verso </td>
                                <td style={{ gap: 10, display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                    {docVerso && <button className="btn btn-success" onClick={() => handleVerAnexo(docVerso)}><FaEye size={25} /></button>}
                                    {docVerso && <button className="btn btn-danger" disabled={readOnly} onClick={() => handleLimparImage(setDocVerso)}><FaTrash size={25} /></button>}
                                    {!docVerso && <button disabled={readOnly} onClick={() => SelecionarArquivo('docVerso')} className="btn btn-primary"><FaPaperclip size={25} /></button>}
                                </td>
                                {isEdit && docVerso && objAnexoVerso && <td><button disabled={readOnly} className="btn btn-danger" onClick={() => handleResucarAnexo(objAnexoVerso, docVerso)}><MdBlock size={25} /></button></td>}
                            </tr>
                            <tr>
                                <td>{docSelfie ? <FcImageFile size={30} /> : <FcRemoveImage size={30} />} <strong style={{ color: docSelfie ? "green" : "red" }}>{docSelfie ? "SELECIONADO" : "PENDENTE"}</strong></td>
                                <td>Selfie</td>
                                <td style={{ gap: 10, display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                    {docSelfie && <button className="btn btn-success" onClick={() => handleVerAnexo(docSelfie)}><FaEye size={25} /></button>}
                                    {docSelfie && <button disabled={readOnly} className="btn btn-danger" onClick={() => handleLimparImage(setDocSelfie)}><FaTrash size={25} /></button>}
                                    {!docSelfie && <button disabled={readOnly} onClick={() => SelecionarArquivo('docSelfie')} className="btn btn-primary"><FaPaperclip size={25} /></button>}
                                </td>
                                {isEdit && docSelfie && objAnexoSelfie && <td><button disabled={readOnly} className="btn btn-danger" onClick={() => handleResucarAnexo(objAnexoSelfie, docSelfie)}><MdBlock size={25} /></button></td>}
                            </tr>
                        </tbody>
                    </table>
                </div>
                {isEdit && <>
                    <hr />
                    <strong>Histórico</strong><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <table className="table table-striped" style={{ width: '80%' }}>
                            <thead>
                                <tr>
                                    <th>Data/Hora</th>
                                    <th>Status</th>
                                    <th>Observacao</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {historico.map(x => (<tr key={x.id}>
                                    <td>{getDateFormataraDDMMYYYYHHmmSS(x.dataCadastro)}</td>
                                    <td>{x.statusProposta.nome}</td>
                                    <td>{x.observacao}</td>
                                    {x.statusPropostaId === 99 && <td><button disabled={readOnly} className="btn btn-success" onClick={() => handdleBuscarAnexoHistorico(x.id)}><FaEye size={25} /></button></td>}
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </>}
            </div>
        </Container>
    );
};

export default Proposta;
