import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';

export default props => {
    const { formData, handleChange, handleSubmit, handleValidarCPf, handleLimpar, handleBuscarbyCep, readOnly, isEdit } = props;
    const [errors, setErrors] = useState({});

    const handleBlur = (e) => {
        const { id, value } = e.target;
        let newErrors = { ...errors };

        if (!value) {
            newErrors[id] = "Campo obrigatório";
        } else {
            delete newErrors[id];
        }

        setErrors(newErrors);
    }

    return <Form onSubmit={e => { e.preventDefault(); handleSubmit() }}>
        <strong>DADOS PESSOAIS</strong><br /><br />
        <Row>
            <Col md={2}>
                <Form.Group controlId="cpf">
                    <Form.Label>CPF*</Form.Label>
                    <InputMask
                        disabled={readOnly}
                        mask="999.999.999-99"
                        value={formData.cpf}
                        onChange={handleChange}
                        onBlur={(e) => { handleBlur(e); !readOnly && handleValidarCPf(e); }}
                        minLength={14}
                        style={{ background: readOnly ? '#e9ecef' : '#fff' }}
                    >
                        {(inputProps) => <Form.Control {...inputProps} type="text" className={errors.cpf ? 'error-border' : ''} />}
                    </InputMask>
                    {errors.cpf && <small className="error-message">{errors.cpf}</small>}
                </Form.Group>
            </Col>
            <Col md={4}>
                <Form.Group controlId="nome">
                    <Form.Label>Nome*</Form.Label>
                    <Form.Control
                        disabled={readOnly}
                        type="text"
                        value={formData.nome}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.nome ? 'error-border' : ''}
                    />
                    {errors.nome && <small className="error-message">{errors.nome}</small>}
                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group controlId="estadoCivil">
                    <Form.Label>Estado Civil*</Form.Label>
                    <select
                        disabled={readOnly}
                        value={formData.estadoCivil}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control ${errors.estadoCivil ? 'error-border' : ''}`}
                        id="estadoCivil"
                        required=""
                        name="estadoCivil"
                        autoComplete="off"
                    >
                        <option value="">Selecione</option>
                        <option value="Solteiro">Solteiro</option>
                        <option value="Casado">Casado</option>
                        <option value="Viuvo">Viúvo</option>
                        <option value="Separado">Separado judicialmente</option>
                        <option value="UniaoEstavel">União Estável</option>
                        <option value="Outros">Outros</option>
                    </select>
                    {errors.estadoCivil && <small className="error-message">{errors.estadoCivil}</small>}
                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group controlId="sexo">
                    <Form.Label>Sexo</Form.Label>
                    <select
                        disabled={readOnly}
                        value={formData.sexo}
                        onChange={handleChange}
                        className="form-control"
                        id="sexo"
                        name="sexo"
                        autoComplete="off"
                    >
                        <option value="Masculino">Masculino</option>
                        <option value="Feminino">Feminino</option>
                        <option value="Outro">Outro</option>
                    </select>
                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group controlId="dataNascimento">
                    <Form.Label>Data de Nascimento*</Form.Label>
                    <InputMask
                        disabled={readOnly}
                        mask="99/99/9999"
                        value={formData.dataNascimento}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        minLength={10}
                        style={{ background: readOnly ? '#e9ecef' : '#fff' }}
                    >
                        {(inputProps) => <Form.Control {...inputProps} type="text" className={errors.dataNascimento ? 'error-border' : ''} />}
                    </InputMask>
                    {errors.dataNascimento && <small className="error-message">{errors.dataNascimento}</small>}
                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group controlId="matricula">
                    <Form.Label>Matrícula*</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={readOnly}
                        value={formData.matricula}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.matricula ? 'error-border' : ''}
                    />
                    {errors.matricula && <small className="error-message">{errors.matricula}</small>}
                </Form.Group>
            </Col>
        </Row>
        <hr />
        <strong>IDENTIDADE</strong><br /><br />
        <Row>
            <Col md={3}>
                <Form.Group controlId="identidade">
                    <Form.Label>N° Identidade*</Form.Label>
                    <Form.Control
                        disabled={readOnly}
                        type="text"
                        value={formData.identidade}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.identidade ? 'error-border' : ''}
                    />
                    {errors.identidade && <small className="error-message">{errors.identidade}</small>}
                </Form.Group>
            </Col>
            <Col md={3}>
                <Form.Group controlId="ufIdentidade">
                    <Form.Label>UF Identidade</Form.Label>
                    <Form.Control disabled={readOnly} type="text" value={formData.ufIdentidade} onChange={handleChange} />
                </Form.Group>
            </Col>
            <Col md={3}>
                <Form.Group controlId="orgaoEmissor">
                    <Form.Label>Órgão Emissor</Form.Label>
                    <Form.Control disabled={readOnly} type="text" value={formData.orgaoEmissor} onChange={handleChange} />
                </Form.Group>
            </Col>
            <Col md={3}>
                <Form.Group controlId="dataEmissao">
                    <Form.Label>Data Emissão</Form.Label>
                    <InputMask
                        disabled={readOnly}
                        mask="99/99/9999"
                        value={formData.dataEmissao}
                        onChange={handleChange}
                        style={{ background: readOnly ? '#e9ecef' : '#fff' }}
                    >
                        {(inputProps) => <Form.Control {...inputProps} type="text" />}
                    </InputMask>
                </Form.Group>
            </Col>
        </Row>
        <hr />
        <strong>DADOS GERAIS</strong><br /><br />
        <Row>
            <Col md={2}>
                <Form.Group controlId="telefoneCelular">
                    <Form.Label>Telefone Celular*</Form.Label>
                    <InputMask
                        disabled={readOnly}
                        mask="(99) 9 9999-9999"
                        value={formData.telefoneCelular}
                        onChange={handleChange}
                        onBlur={readOnly ? () => { } : handleBlur}
                        style={{ background: readOnly ? '#e9ecef' : '#fff' }}
                    >
                        {(inputProps) => <Form.Control {...inputProps} type="text" className={errors.telefoneCelular ? 'error-border' : ''} />}
                    </InputMask>
                    {errors.telefoneCelular && <small className="error-message">{errors.telefoneCelular}</small>}
                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group controlId="telefoneFixo">
                    <Form.Label>Telefone Fixo</Form.Label>
                    <InputMask
                        disabled={readOnly}
                        style={{ background: readOnly ? '#e9ecef' : '#fff' }}
                        mask="(99) 9999-9999"
                        value={formData.telefoneFixo}
                        onChange={handleChange}
                    >
                        {(inputProps) => <Form.Control {...inputProps} type="text" />}
                    </InputMask>
                </Form.Group>
            </Col>
            <Col md={8}>
                <Form.Group controlId="nomeMae">
                    <Form.Label>Nome da Mãe*</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={readOnly}
                        value={formData.nomeMae}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.nomeMae ? 'error-border' : ''}
                    />
                    {errors.nomeMae && <small className="error-message">{errors.nomeMae}</small>}
                </Form.Group>
            </Col>
        </Row><br />
        <Row>
            <Col md={6}>
                <Form.Group controlId="emailPessoal">
                    <Form.Label>Email Pessoal</Form.Label>
                    <Form.Control
                        type="email"
                        disabled={readOnly}
                        value={formData.emailPessoal}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group controlId="emailCorporativo">
                    <Form.Label>Email Corporativo</Form.Label>
                    <Form.Control
                        disabled={readOnly}
                        type="email"
                        value={formData.emailCorporativo}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Col>
        </Row>
        <hr />
        <strong>ENDEREÇO</strong><br /><br />
        <Row>
            <Col md={2}>
                <Form.Group controlId="cep">
                    <Form.Label>CEP*</Form.Label>
                    <InputMask
                        disabled={readOnly}
                        mask="99999-999"
                        style={{ background: readOnly ? '#e9ecef' : '#fff' }}
                        value={formData.cep}
                        onChange={handleBuscarbyCep}
                        onBlur={readOnly ? () => { } : handleBlur}
                    >
                        {(inputProps) => <Form.Control {...inputProps} type="text" className={errors.cep ? 'error-border' : ''} />}
                    </InputMask>
                    {errors.cep && <small className="error-message">{errors.cep}</small>}
                </Form.Group>
            </Col>
            <Col md={5}>
                <Form.Group controlId="logradouro">
                    <Form.Label>Logradouro*</Form.Label>
                    <Form.Control
                        disabled={readOnly}
                        type="text"
                        value={formData.logradouro}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.logradouro ? 'error-border' : ''}
                    />
                    {errors.logradouro && <small className="error-message">{errors.logradouro}</small>}
                </Form.Group>
            </Col>
            <Col md={1}>
                <Form.Group controlId="numero">
                    <Form.Label>Número*</Form.Label>
                    <Form.Control
                        disabled={readOnly}
                        type="text"
                        value={formData.numero}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.numero ? 'error-border' : ''}
                    />
                    {errors.numero && <small className="error-message">{errors.numero}</small>}
                </Form.Group>
            </Col>
            <Col md={4}>
                <Form.Group controlId="complemento">
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control disabled={readOnly} type="text" value={formData.complemento} onChange={handleChange} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={5}>
                <Form.Group controlId="bairro">
                    <Form.Label>Bairro*</Form.Label>
                    <Form.Control
                        disabled={readOnly}
                        type="text"
                        value={formData.bairro}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.bairro ? 'error-border' : ''}
                    />
                    {errors.bairro && <small className="error-message">{errors.bairro}</small>}
                </Form.Group>
            </Col>
            <Col md={5}>
                <Form.Group controlId="cidade">
                    <Form.Label>Cidade*</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={readOnly}
                        value={formData.cidade}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.cidade ? 'error-border' : ''}
                    />
                    {errors.cidade && <small className="error-message">{errors.cidade}</small>}
                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group controlId="uf">
                    <Form.Label>UF*</Form.Label>
                    <Form.Control
                        disabled={readOnly}
                        type="text"
                        value={formData.uf}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.uf ? 'error-border' : ''}
                    />
                    {errors.uf && <small className="error-message">{errors.uf}</small>}
                </Form.Group>
            </Col>
        </Row>
        <br />
        <hr />
        <strong>DOCUMENTOS</strong><br />
        <div className="footer-proposta-container">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 20, padding: 5 }}>
                {!readOnly && <button type='submit' className="btn btn-primary">Salvar</button>}
                {!readOnly && !isEdit && <button type='button' onClick={handleLimpar} className="btn btn-info">Limpar</button>}
                {<button type='button' onClick={() => window.location.href = '/propostas'} className="btn btn-info">Voltar</button>}
            </div>
        </div>
    </Form >
}