import Login from "./views/Login/Login";
import Proposta from "./views/Proposta/proposta";
import { createBrowserRouter } from 'react-router-dom';
import Propostas from "./views/Propostas/propostas";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/Proposta",
    element: <Proposta />,
  },
  {
    path: "/Propostas",
    element: <Propostas />,
  },
]);

export default router;
