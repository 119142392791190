import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaUser, FaKey, FaEye, FaEyeSlash } from 'react-icons/fa'

export default props => {
    const {
        login,
        setLogin,
        submit,
        showSenha,
        setSenha,
        senha,
        setShowSenha,
        BuscarCaptcha,
        Captcha,
        captchaDigitado,
        ip,
        onChangeCaptha, } = props;

    return <form className="container-box" onSubmit={e => { e.preventDefault(); submit() }}>
        <h3 style={{ color: '#000', textAlign: 'center' }}>Efetuar Login</h3>
        <h6 style={{ color: "#b5b5c3", textAlign: 'center' }} >Seu ip é: {ip}</h6>
        <br />
        <label style={{ color: '#000' }}>Login</label>
        <InputGroup className="mb-12">
            <InputGroup.Text><FaUser color="#000" /></InputGroup.Text>
            <Form.Control
                required
                placeholder="Login"
                aria-label="Login"
                aria-describedby="basic-addon1"
                value={login}
                onChange={e => setLogin(e.target.value)}
            />
        </InputGroup><br />
        <label style={{ color: '#000' }}>Senha</label>
        <InputGroup className="mb-12">
            <InputGroup.Text><FaKey color="#000" /></InputGroup.Text>
            <Form.Control
                required
                type={showSenha ? "text" : "password"}
                placeholder="Senha"
                aria-label="Senha"
                aria-describedby="basic-addon1"
                value={senha}
                onChange={e => setSenha(e.target.value)}
            />
            <InputGroup.Text>
                <button onClick={() => setShowSenha(!showSenha)} type="button" style={{ background: 'none', border: 'none' }}>
                    {showSenha ? <FaEye color="#000" /> : <FaEyeSlash color="#000" />}
                </button>
            </InputGroup.Text>
        </InputGroup>
        {/* <br />
        <button type='button' onClick={BuscarCaptcha} className="btn btn-link">Recarregar imagem</button><br />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src={`data:image/png;base64,${Captcha}`} alt="captcha" height={85} width={200} />
            <input placeholder="Escrever exatamente igual" className="form-control" style={{ width: '100%' }} type="text" value={captchaDigitado} onChange={onChangeCaptha} />
        </div> */}
        <br />
        <button style={{ width: '100%' }} className="btn btn-primary">Continuar</button>
    </form>
}