import React from 'react';
import { Modal } from 'react-bootstrap';

class ModalViisualizarImagem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            imagem: ''
        }
        this.ViisualizarImagem = (base64) => this.setState({ show: true, imagem: base64 });
    }
    render() {
        return (
            <Modal show={this.state.show}>
                <Modal.Header>
                    Viisualizar Imagem
                </Modal.Header>
                <Modal.Body>
                    <img src={this.state.imagem} alt='anexo' style={{
                        width: '100%',
                        maxHeight: '500px'
                    }} />
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-info' onClick={() => this.setState({ show: false, imagem: '' })}>Voltar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalViisualizarImagem;