import { useEffect, useState, useRef } from "react";
import Form from './Form.js'
import { PostValidarLogin, GetIp, GetCaptcha } from "../Api/methodsApi.js";
import Loader from '../../components/Loader/loader.js';

function Login() {
    const [ip, setIp] = useState('');
    const [showSenha, setShowSenha] = useState(false);
    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [captchaDigitado, setCaptchaDigitado] = useState('');
    const [token, setToken] = useState('');
    const loader = useRef();

    const submit = () => {
        loader.current.showLoader();
        const form = {
            "login": login,
            "senha": senha,
            // "captcha": captchaDigitado,
            // "token": token
        }
        
        PostValidarLogin(form, res => {
            window.localStorage.setItem("access_token", btoa(res.data.token))
            window.localStorage.setItem("revendedor_id", btoa(res.data.revendedorId))
            window.location.href = '/Propostas';
        });
        
        loader.current.hideLoader();
    }

    const BuscarCaptcha = () => {
        GetCaptcha(res => {
            setCaptcha(res.data.image);
            setToken(res.data.text)
        }, err => {
            alert(err.response.data);
        })
    }

    const BuscarIp = () => {
        GetIp(res => {
            setIp(res.data);
        }, err => {
            alert(err.response.data);
        });
    }

    useEffect(() => {
        BuscarCaptcha();
        BuscarIp();
    }, [])

    const onChangeCaptha = e => setCaptchaDigitado(e.target.value);

    return (
        <div className="container-center">
            <Loader ref={loader} />
            <Form
                login={login}
                setLogin={setLogin}
                submit={submit}
                showSenha={showSenha}
                setSenha={setSenha}
                senha={senha}
                setShowSenha={setShowSenha}
                BuscarCaptcha={BuscarCaptcha}
                Captcha={captcha}
                captchaDigitado={captchaDigitado}
                ip={ip}
                onChangeCaptha={onChangeCaptha}
            />
        </div>
    );
}

export default Login;
