import React, { useEffect, useState, useRef } from 'react';
import { api } from '../Api/api';
import Loader from '../../components/Loader/loader';
import { FaCubes, FaDownload, FaEdit, FaFile, FaFilter, FaPaperclip, FaSearch } from 'react-icons/fa';
import { FaClone, FaTrash } from 'react-icons/fa6';
import { Container } from 'react-bootstrap';
import { Alert, Pergunta } from '../../components/Alert';
import ModalCancelarProposta from '../../components/ModalCancelarProposta';

function Propostas() {
    const [propostas, setPropostas] = useState([])
    const cancelarProposta = useRef();
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [qtdPaginas, setQtdPaginas] = useState(1);
    const [qtdTotal, setQtdTotal] = useState(1);
    const [showFiltros, SetShowFiltros] = useState(false);
    const [status, setStatus] = useState([]);
    const [nomeOuCpf, setNomeOuCpf] = useState('');
    const [dtInicio, setDtInicio] = useState('');
    const [dtFim, setDtFim] = useState('');
    const [statusSelecionado, setStatusSelecionado] = useState(0);

    const BuscarPropostas = (pagina, dataCadastroInicio, dataCadastroFim, statusId, termoDaBusca) => {

        let PaginaDesejada = pagina ? pagina : paginaAtual;

        let filtros = '';

        dataCadastroInicio && (filtros += `&DataCadastroInicio=${dataCadastroInicio}`);
        dataCadastroFim && (filtros += `&DataCadastroFim=${dataCadastroFim}`);

        if (!dataCadastroInicio && dataCadastroFim || dataCadastroInicio && !dataCadastroFim) {
            Alert('Selecione o periodo completo: Data do periodo de inicio e Data do periodo final', false, true)
            return;
        }

        statusId && (filtros += `&statusId.0=${statusId}`);
        termoDaBusca && (filtros += `&termoDaBusca=${termoDaBusca}`);

        api.get(`api/Proposta/ListarPropostas?Paginacao.PaginaDesejada=${PaginaDesejada}&Paginacao.ResultadosPorPagina=10&Paginacao.Ordenacao=Id&Paginacao.OrdemDescendente=true${filtros}`, res => {
            setQtdTotal(res.data.qtdTotal);
            setQtdPaginas(res.data.qtdPaginas);
            setPropostas(res.data.resultado);
        }, err => {
            if (err.response.status === 404) {
                Alert('Nenhuma proposta encontrada.', false, true);
                setQtdTotal(0);
                setQtdPaginas(1);
                setPropostas([]);
            }
        })
    }

    const BuscarStatus = () => {
        api.get('api/Proposta/ListarStatus', res => setStatus(res.data), err => Alert(err.response.data, true));
    }

    useEffect(() => {
        BuscarPropostas();
        BuscarStatus();

    }, []);

    function getDateFormataraDDMMYYYY(date) {
        if (!date) return null;

        const dataObj = new Date(date);
        const dia = String(dataObj.getDate()).padStart(2, '0');
        const mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
        const ano = dataObj.getFullYear();
        return `${dia}/${mes}/${ano}`;
    }

    function copyLink(link) {
        navigator.clipboard.writeText(link).then(function () {
            Alert("Link copiado para a área de transferência!");
        }, function (err) {
            console.error("Erro ao copiar o link: ", err);
        });
    }

    async function CancelarProposta(id) {
        cancelarProposta.current.AbrirModal(id);
    }

    const handdleBuscarPropostasFiltros = () => {
        setPaginaAtual(1);
        BuscarPropostas(1, dtInicio, dtFim, statusSelecionado === 0 ? null : statusSelecionado, nomeOuCpf === '' ? null : nomeOuCpf);
    }

    const AlterarPagina = async (pagina, isProxima) => {
        let buscar = false;

        if (isProxima && (paginaAtual + pagina) > qtdPaginas) {
            if (await Pergunta("Numero da página digitada maior que quantidade de paginas\nDeseja buscar pelo numero maximo?")) {
                setPaginaAtual(qtdPaginas);
                buscar = true;
                BuscarPropostas(qtdPaginas)
            } else {
                return;
            }
        } else if (!isProxima && (paginaAtual - pagina) <= 0) {
            if (await Pergunta("Numero da página digitada menor que 1\nDeseja buscar pelo numero minimo?")) {
                setPaginaAtual(1);
                buscar = true;
                BuscarPropostas(1)
            } else {
                return;
            }
        } else {
            if (isProxima) {
                setPaginaAtual(paginaAtual + pagina);
                BuscarPropostas(paginaAtual + pagina)
            } else {
                setPaginaAtual(paginaAtual - pagina);
                BuscarPropostas(paginaAtual - pagina)
            }
        }
    }

    return (
        <Container className="container-center" style={{ padding: '2.5rem', borderRadius: 20 }}>
            <Loader />
            <ModalCancelarProposta ref={cancelarProposta} />
            <div className="container-proposta" style={{ minHeight: '100vh' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4><FaCubes /> Propostas</h4>
                </div>
                <hr />
                <div style={{ display: 'flex', gap: 5, justifyContent: 'start', alignItems: 'center' }}>
                    <button className='btn btn-primary' onClick={() => window.location.href = '/proposta'}><FaFile /> Novo</button>
                    <button style={{ color: "#fff" }} className='btn btn-info' onClick={() => SetShowFiltros(!showFiltros)}><FaFilter /> Mostrar Filtros</button>
                </div>

                <br />
                {showFiltros && <div className='container'>
                    <div className='row'>
                        <hr />
                        <small>Filtros:</small>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nome ou CPF</label>
                                <input value={nomeOuCpf} onChange={e => setNomeOuCpf(e.target.value)} type="text" className='form-control' />
                            </div>
                            <div className="col-md-3">
                                <label>Periodo Inicio</label>
                                <input value={dtInicio} onChange={e => setDtInicio(e.target.value)} type="date" className='form-control' />
                            </div>
                            <div className="col-md-3">
                                <label>Periodo Fim</label>
                                <input value={dtFim} onChange={e => setDtFim(e.target.value)} type="date" className='form-control' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Status</label>
                                <select className='form-control' value={statusSelecionado} onChange={e => setStatusSelecionado(e.target.value)}>
                                    <option value={0}>SELECIONE</option>
                                    {status.map(x => (<option key={x.id} value={x.id}>{x.nome}</option>))}
                                </select>
                            </div>
                            {/* <div className="col-md-4">
                                <label>Empresa</label>
                                <input placeholder='Digite o nome da empresa...' type="text" className='form-control' />
                            </div>
                            <div className="col-md-4">
                                <label>Usuario</label>
                                <input placeholder='Digite o nome do usuario...' type="text" className='form-control' />
                            </div> */}
                        </div>
                        {/* <div className="row">
                            <div className="col-md-4">
                                <label>Produto</label>
                                <select className='form-control'>
                                    <option value={0}>SELECIONE</option>
                                    {[{ id: 6, nome: 'AASPA' }].map(x => (<option value={x.id}>{x.nome}</option>))}
                                </select>
                            </div>
                        </div> */}
                        <br /><br />
                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%', gap: 10 }}>
                            <button className='btn btn-primary' onClick={handdleBuscarPropostasFiltros}><FaSearch /> Pesquisar</button>
                            {/* <button style={{ color: "#fff" }} className='btn btn-info' onClick={() => SetShowFiltros(!showFiltros)}><FaDownload /> Download Resultados</button> */}
                        </div>
                    </div>
                </div>}

                <hr />
                <small>Total Propostas: {qtdTotal}</small>
                <div style={{ width: '100%', overflow: 'auto' }}>
                    <table id="tableList" className="table table-striped">
                        <thead className='thead-dark'>
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th>Cpf</th>
                                <th>Telefone</th>
                                <th>Produto</th>
                                <th>Status</th>
                                <th>Empresa</th>
                                <th>Vendedor</th>
                                <th>Data</th>
                                <th>Acoes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                propostas.map(x =>
                                    <tr className="selecao" key={x.id}>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.id}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.nomeCliente}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.cpf}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.telefonePessoal}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.nomeProduto}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.nomeStatus}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.nomeRevendedor}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{x.nomeVendedor}</td>
                                        <td onClick={e => window.location.href = `/proposta?Id=${x.id}&readOnly`}>{getDateFormataraDDMMYYYY(x.dataCadastro)}</td>
                                        <td style={{ zoom: 0.6 }} className="no-click" >
                                            <div style={{ display: 'flex', gap: 10 }}>
                                                <button className='btn btn-warning' style={{ marginRight: 5 }} onClick={() => window.location.href = '/proposta?Id=' + x.id}>
                                                    <FaEdit color='#fff' />
                                                </button>
                                                <button className='btn btn-info' onClick={() => copyLink(x.linkKompletoCliente)}>
                                                    <FaClone color='#fff' />
                                                </button>
                                                <button className='btn btn-danger' style={{ marginRight: 5 }} onClick={() => CancelarProposta(x.id)}>
                                                    <FaTrash color='#fff' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <hr />
                <div style={{ display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={() => { AlterarPagina(5, false) }} disabled={paginaAtual === 1} className='btn btn-primary'>-5</button>
                    <button onClick={() => { AlterarPagina(10, false) }} disabled={paginaAtual === 1} className='btn btn-primary'>-10</button>
                    <button onClick={() => { AlterarPagina(1, false) }} disabled={paginaAtual === 1} className='btn btn-primary'>Anterior</button>
                    <div style={{ display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center' }}>
                        <span>{paginaAtual}</span>
                        <span>De</span>
                        <span>{qtdPaginas}</span>
                    </div>
                    <button onClick={() => { AlterarPagina(1, true) }} disabled={paginaAtual === qtdPaginas} className='btn btn-primary'>Proximo</button>
                    <button onClick={() => { AlterarPagina(5, true) }} disabled={paginaAtual === qtdPaginas} className='btn btn-primary'>+5</button>
                    <button onClick={() => { AlterarPagina(10, true) }} disabled={paginaAtual === qtdPaginas} className='btn btn-primary'>+10</button>
                </div>
            </div>
        </Container >
    );
}

export default Propostas;